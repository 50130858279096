// const addScript = (url) => {
//   const script = document.createElement("script")
//   script.src = url
//   script.id = "hs-script-loader"
//   script.async = true
//   script.defer = true
//   document.body.appendChild(script)
// }

export const onClientEntry = () => {
  // window.onload = () => {
  //   setTimeout(() => addScript("//js.hs-scripts.com/8706227.js"), 5000)
  // }
}
