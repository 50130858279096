// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-courses-index-tsx": () => import("./../../../src/pages/courses/index.tsx" /* webpackChunkName: "component---src-pages-courses-index-tsx" */),
  "component---src-templates-about-us-page-tsx": () => import("./../../../src/templates/about-us-page.tsx" /* webpackChunkName: "component---src-templates-about-us-page-tsx" */),
  "component---src-templates-combo-course-tsx": () => import("./../../../src/templates/combo-course.tsx" /* webpackChunkName: "component---src-templates-combo-course-tsx" */),
  "component---src-templates-contact-us-page-tsx": () => import("./../../../src/templates/contact-us-page.tsx" /* webpackChunkName: "component---src-templates-contact-us-page-tsx" */),
  "component---src-templates-course-tsx": () => import("./../../../src/templates/course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */),
  "component---src-templates-enrol-page-tsx": () => import("./../../../src/templates/enrol-page.tsx" /* webpackChunkName: "component---src-templates-enrol-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-privacy-page-tsx": () => import("./../../../src/templates/privacy-page.tsx" /* webpackChunkName: "component---src-templates-privacy-page-tsx" */),
  "component---src-templates-terms-page-tsx": () => import("./../../../src/templates/terms-page.tsx" /* webpackChunkName: "component---src-templates-terms-page-tsx" */)
}

